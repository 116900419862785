/* eslint-disable camelcase */
import { graphql } from 'gatsby'
import React from 'react'
import { Linkedin } from 'react-feather'
import Layout from '../components/Layout'
import Pagination from '../components/Pagination'
import PostList from '../components/PostList'

const Author = props => {
  const { data } = props
  const {
    authored_wordpress__POST,
    name,
    description,
    avatar_urls: { wordpress_96 },
    acf: { linkedin, position },
  } = data.wordpressWpUsers

  // The `authored_wordpress__POST` returns a simple array instead of an array
  // of edges / nodes. We therefore need to convert the array here.
  const posts = authored_wordpress__POST
    ? authored_wordpress__POST.map(post => ({
        node: post,
      }))
    : []

  const { pageContext } = props
  const filteredPosts = posts.filter(({ node: post }) => post.slug !== 'filler')

  return (
    <Layout lightText seo={{ pageTitle: name }}>
      <section className="hero has-gradient-darkgrey-grey is-bold">
        <div className="full-width-image-container-dark">
          <div className="hero-body">
            <div className="container">
              <div className="content">
                <div className="media">
                  <figure className="media-left m-auto">
                    <p className="image is-96x96">
                      <img src={wordpress_96} alt="Author profile gravatar" />
                    </p>
                  </figure>
                  <div className="media-content pl-16 m-auto">
                    <h1 className="title has-text-white is-marginless">
                      {name || 'Unknown'}
                    </h1>
                    {position && (
                      <p className="subtitle has-text-white">{position}</p>
                    )}
                    {linkedin && (
                      <a
                        href={linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="is-link"
                      >
                        <Linkedin />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section--small">
        <div className="container">
          <div className="content">
            <h5>Bio</h5>
            <p>{description || 'No description'}</p>
            <h5>Posts</h5>
            <PostList posts={filteredPosts} animate={false} />
            {filteredPosts.length > 9 && (
              <div className="p-4">
                <Pagination pageContext={pageContext} pathPrefix="/" />
              </div>
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Author

export const pageQuery = graphql`
  query AuthorPage($id: String!) {
    wordpressWpUsers(id: { eq: $id }) {
      id
      name
      description
      path
      avatar_urls {
        wordpress_96
      }
      authored_wordpress__POST {
        ...PostFields
      }
      acf {
        position
        linkedin
      }
    }
  }
`
